<template>
  <b-card body-class="p-0 mb-2" header-tag="header" header-class="card-row rounded">
    <template #header>
      <b-container fluid class="px-2">
        <b-row>
          <b-col cols="auto" class="p-0">
            <ComputerOnline :computer="computer"/>
            <UserConnected :computer="computer"/>
            <WinUser :computer="computer"/>
          </b-col>
          <b-col cols="2">
            <Clipboard :value="computer.hostname" title="Hostname kopieren"/>
          </b-col>
          <b-col cols="1" class="px-0">
            <Clipboard :value="computer.ipv4_address" title="IPv4-Adresse kopieren"/>
          </b-col>
          <b-col cols="1">
            <HardwareInformation :computer="computer"/>
          </b-col>
          <b-col cols="6" class="ml-5">
            <b-row class="pl-2">
              <b-col cols="4" class="p-0">
                <span :id="'tt-issued-at-' + computer.id" :class="spanClasses">
                  <b-icon-lock-fill class="mr-1" scale="1.1" :variant="variantSuccess"/> {{ computer.issued_at | datetime }}
                </span>
                <b-tooltip :target="'tt-issued-at-' + computer.id" triggers="hover">Austellungszeitpunkt des VPN-Zertifikats</b-tooltip>
              </b-col>
              <b-col cols="6" class="p-0">
                <span :id="'tt-expire-at-' + computer.id" :class="spanClasses">
                  <b-icon-lock-fill class="mr-1" scale="1.1" :variant="variantDanger"/> {{ computer.expire_at | datetime}}
                </span>
                <b-tooltip :target="'tt-expire-at-' + computer.id" triggers="hover">Ablaufzeitpunkt des VPN-Zertifikats</b-tooltip>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="auto" class="ml-auto p-0">
            <ComputerWarnings :computer="computer"/>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import Clipboard from "@/components/utility/Clipboard.vue";
import WinUser from "@/components/lists/computerList/computerRow/WinUser";
import UserConnected from "@/components/lists/computerList/computerRow/UserConnected";
import ComputerOnline from "@/components/lists/computerList/computerRow/ComputerOnline";
import ComputerWarnings from "@/components/lists/computerList/computerRow/ComputerWarnings";
import HardwareInformation from "@/components/lists/computerList/computerRow/HardwareInformation";

export default {
  name: "VPNRow",
  props: ['computer'],
  components: {
    WinUser,
    Clipboard,
    UserConnected,
    ComputerOnline,
    ComputerWarnings,
    HardwareInformation
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    },
    spanClasses() {
      if(this.userThemeId === 2) { return '' }
      return 'text-white'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/Color.scss";

.dark .card-row:hover {
  background-color: $grey-700 !important;
}

.light .card-row:hover {
  background-color: $grey-100 !important;
}
</style>
